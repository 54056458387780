<template>
  <div class="content">
    <el-carousel height="200px" :initial-index="0" loop class="carousel" indicator-position="none" arrow="never">
      <el-carousel-item>
        <img src="https://besoss002.oss-cn-shanghai.aliyuncs.com/attach/2024/05/de6d5202405141711475446.jpg"/>
      </el-carousel-item>
      <!-- <el-carousel-item>
        <img src="https://besoss002.oss-cn-shanghai.aliyuncs.com/attach/2024/05/41396202405141559198723.jpg"/>
      </el-carousel-item> -->
      <!-- <el-carousel-item>
        <img src="https://besoss003.oss-cn-beijing.aliyuncs.com/attach/2024/04/45a6e202404191439307351.jpg"/>
      </el-carousel-item> -->
    </el-carousel>

    <div class="nav acea-row row-between">
      <div class="item name-activate" :class="type === 'name-activate' ? 'active' : ''" @click="type = 'name-activate'">实名激活</div>
      <div class="item flow" :class="type === 'flow' ? 'active' : ''" @click="type = 'flow'">流量充值</div>
      <div class="item points" :class="type === 'points' ? 'active' : ''" @click="type = 'points'">我的积分</div>
    </div>

    <div class="form" v-if="type === 'name-activate'">
      <el-form ref="form" :model="jhData" label-width="80px" label-position="top" v-if="!is_binDing">
				<el-form-item>
				  <el-input v-model="jhData.iccid" prefix-icon="iconfont icon-qiahao" placeholder="请输入卡号ICCID"></el-input>
				</el-form-item>
				<!-- <el-form-item>
				  <el-input v-model="jhData.job_number" prefix-icon="iconfont icon-gonghao" placeholder="请输入君凤煌服务工号"></el-input>
				</el-form-item> -->
        <el-form-item>
				  <el-input v-model="jhData.jhuserphone" prefix-icon="iconfont icon-shoujihao" placeholder="请输入君凤煌手机号"></el-input>
				</el-form-item>
        <div class="active-btn" @click="binDing">确认绑卡</div>
        <div class="video-btn" @click="toVideo">激活教程</div> 
			</el-form>

      <div class="second acea-row row-column row-middle" v-else>
        <div class="text">
          截屏或保存图片，打开微信扫一扫，选择相册，选中该图片
        </div>
        <img src="https://besoss003.oss-cn-beijing.aliyuncs.com/attach/2024/03/40132202403141645103971.png" alt="">
      </div>
    </div>

    <div class="choose-flow" v-if="type === 'flow'">
      <div class="choose-title">选择充值产品:</div>
      <div class="product acea-row row-around">
        <div class="left-img">
          <img src="https://besoss002.oss-cn-shanghai.aliyuncs.com/attach/2024/05/b7ecd202405141406353666.jpg" alt="">
        </div>
        <div class="right-content acea-row">
          <div class="type line2">
            {{ '广电流量卡' }}
          </div>
          <div class="recharge-btn" @click="toTopUp('gd')">充值</div>
        </div>
      </div>
    </div>

    <div class="points-info" v-if="type === 'points'">
      <div class="title">总积分:</div>
      <div class="my_points">{{ pointsTotal }}</div>
      <div class="convert" @click="pointsDialog = true" v-if="!pointsDialog">转换积分</div>
    </div>

    <div class="points-form" v-if="pointsDialog">
      <el-form ref="form" :model="points" size="medium">
        <el-form-item>
          <el-input v-model="points.point" prefix-icon="iconfont icon-jifen" placeholder="请输入要转换的积分"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="points.phone" prefix-icon="iconfont icon-shoujihao" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <div class="btn acea-row row-between">
          <div class="cancel" @click="cancel">取消</div>
          <div class="confirm" @click="confirm">确定</div>
        </div>
      </el-form>
    </div>

    <!-- 激活教程 -->
    <div class="vedio-box" v-if="video">
      <video controls width="100%" height="88%">
        <source src="https://www.ruiwo365.com/uploads/attach/2024/04/30/广电5G卡激活流程2.mp4" type="video/mp4"/>
      </video>
      <div class="close-btn" @click="video = false">关闭</div>  
    </div>
    <div class="mask" v-if="video" @click="video = false"></div>
  </div>
</template>

<script>
import {
  binding,
  conversion
} from '@/api/index'
export default {
  name: 'HomeView',
  data() {
    return {
      is_binDing: false,
      pointsDialog: false,
      video: false,
      type: 'name-activate',
      jhData: {
        iccid: '',
        jhuserphone: ''
      },
      points: {
        point: '',
        phone: '',
      }
    }
  },

  computed: {
    pointsTotal() {
      console.log(this.$store.getters.getUserInfo,'userInfo')
      return this.$store.getters.getUserInfo ? JSON.parse(this.$store.getters.getUserInfo).card_integral : 0
    }
  },

  watch: {
    type() {
      this.reset()
      this.pointsDialog = false
      this.is_binDing = false
    }
  },

  methods: {
    // 绑卡
    binDing() {
      // return this.is_binDing = true
      const that = this
      if(!that.jhData.jhuserphone) return that.$message.warning('请填写手机号')
      if(!/^1(3|4|5|7|8|9|6)\d{9}$/i.test(that.jhData.jhuserphone)) return that.$message.warning('请输入正确的手机号')
      binding(this.jhData).then(res => {
        console.log(res)
        this.$message.success('初步绑定成功，请进行下一步操作')
        this.is_binDing = true
      }).catch(err => console.log(err))
    },
    cancel() {
      this.pointsDialog = false
      this.reset()
    },
    confirm() {
      if(!this.points.phone) return this.$message.warning('请填写手机号')
      if(!/^1(3|4|5|7|8|9|6)\d{9}$/i.test(this.points.phone)) return this.$message.warning('请输入正确的手机号')
      conversion(this.points).then(res => {
        console.log(res)
      }).catch(err => console.log(err))
    },
    // 激活教程视频
    toVideo() {
      this.video = true
    },
    // 重置
    reset() {
      this.points = {
        point: '',
        phone: '',
      }
      this.jhData = {
        iccid: '',
        jhuserphone: ''
      }
    },
    // 去卡/设备详情页
    toTopUp(type) {
      this.$router.push(`/cardDetail/${type}`)
    }
  }
}
</script>

<style scoped lang="scss">
.carousel {
  img {
    width: 100%;
    height: 100%;
  }
}
.nav {
  border-bottom: 1px solid var(--primary-color);

  .item {
    width: 33.12%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: var(--primary-color);
    font-size: 16px;
    cursor: pointer;
    &.active {
      color: #fff;
      background-color: var(--primary-color);
    }
  }
  .name-activate,.flow {
    border-right: 1px solid var(--primary-color);
  }
}

.form {
  width: 85%;
  margin: 40px auto 0;
}

.active-btn {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: var(--primary-color);
  border-radius: 20px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.video-btn {
  width: 100%;
  height: 40px;
  line-height: 40px;
  color: var(--primary-color);
  border-radius: 20px;
  font-size: 16px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
  border: 1px solid var(--primary-color);
}

.choose-flow {
  .choose-title {
    color: var(--primary-color);
    padding: 15px 0 15px 10px;
    font-size: 18px;
  }
}

.product {
  border-bottom: 2px solid rgb(245, 159, 30);
  margin: 0 5px 15px;
  padding: 5px 0;
  .left-img,.right-content{
    width: 45%;
  }
  .right-content{
    text-align: center;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;

    .recharge-btn {
      width: 70%;
      height: 30px;
      line-height: 30px;
      border-radius: 15px;
      background-color: var(--primary-color);
      font-size: 16px;
      color: #fff;
      text-align: center;
      cursor: pointer;
    }

    .type {
      font-size: 14px;
      font-weight: bold;
    }
  }
  img {
    width: 100%;
  }
}

.points-info {
  width: 75%;
  margin: 20px auto 0;
  .title {
    font-size: 18px;
  }
  .my_points {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
    color: var(--primary-color);
  }
  .convert {
    widows: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
  }
}

.points-form {
  width: 75%;
  margin: 0 auto;

  .cancel, .confirm {
    width: 40%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
  }

  .cancel {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
  }

  .confirm {
    color: #fff;
    background-color: var(--primary-color);
  }
}
.second {
  .text {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  img {
    width: 85%;
  }
}
.vedio-box {
  position: absolute;
  z-index: 99;
  background-color: #fff;
  top: 8rem;
  bottom: 8rem;
  left: 2rem;
  right: 2rem;
  // padding: 10px;

  .close-btn {
    position: absolute;
    z-index: 99;
    bottom: 0;
    width: 100%;
    height: 3.5rem;
    line-height: 3.5rem;
    background-color: var(--primary-color);
    color: #fff;
    cursor: pointer;
    text-align: center;
    font-size: 1.3rem;
  }
}
</style>
