import axios from "axios";
import store from '@/store/index'
import app from '@/main'


// 功能失败的错误原因
export const authErrMessage = {
    10031: "登录失效，需要重新登录",
    10032: "您太久没登录，请重新登录~",
    10033: "账户未绑定角色，请联系管理员绑定角色",
    10034: "该用户未注册，请联系管理员注册用户",
    10035: "code 无法获取对应第三方平台用户",
    10036: "该账户未关联员工，请联系管理员做关联",
    10037: "账号已无效",
    10038: "账号未找到",
  };
  
  // 请求失败的错误原因
  export const networkErrMessage = {
    400: "错误的请求",
    401: "未授权，请重新登录",
    403: "拒绝访问",
    404: "未找到该资源",
    405: "请求方法未允许",
    408: "请求超时",
    500: "服务器端出错",
    502: "网络错误",
    503: "服务不可用",
    504: "网络超时",
    505: "http版本不支持该请求",
  };

// 创建一个 axios 实例
const request = axios.create({
    // baseURL: `http://loc.puhuizn.com:8088/api`,
    // baseURL: `https://www.puhuiwl.com/api`,
    // baseURL: `https://www.ruiwo365.com/api`,
    baseURL: `https://unicom.puhuiwl.com/api`,
    timeout: 600000 // 请求超时时间
});

// 在跨域请求中发送身份验证信息（例如 cookies、HTTP 认证）
axios.defaults.withCredentials = true;

// 请求拦截器
request.interceptors.request.use((config) => {
    const token = store.getters.getToken;
    if (token) {
        config.headers['Authori-zation'] = 'Bearer '+token;
    }
    config.headers['Content-Type'] = 'application/json'
    config.headers['Form-type'] = 'h5'
    // console.log(config)
    return config;
})

request.interceptors.response.use(
    (response) => {
        // console.log(response,'response')
        const code = response.data.status;
        if (code === 200) {
          return response.data.data;
        } else {
          // let errMessage = authErrMessage[code];
          // if (errMessage) {
          //   // 功能出错，需要特殊处理，比如要退出登录等
          //   // useUserStore().logout();
          // } else {
          //   errMessage = response.data.message;
          // }
          app.$message.error(response.data.msg);
          return Promise.reject(response.data.msg);
        }
      },
      (error) => {
        // console.log(error,'error')
        let errMessage = "未知错误，请联系管理员解决";
        if (error.response) {
          // error.response有值，说明服务器有响应，响应结果是错误的，要根据响应状态码error.response.status来提示错误
          errMessage = networkErrMessage[error.response.status];
        } else {
          // error.response没有值，说明服务器没有响应，请求在客户端就失败了，要根据浏览器提示的错误信息来处理
          if (error.message.indexOf("timeout") > -1) {
            // 请求超时
            errMessage = "当前网络环境不稳定，请切换4/5G网络或WIFI网络试试";
          } else if (error.message.indexOf("Network") > -1) {
            // 断网了
            errMessage = "没有检测到网络，请打开网络链接试试";
          } else if (error.message.indexOf("canceled") > -1) {
            errMessage = "请求取消了";
          }
        }
        app.$message.error(errMessage);
        return Promise.reject(errMessage);
      }
)

export default request;