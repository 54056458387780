import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UseLogin.vue'),
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPassword.vue'),
  },
  {
    path: '/agreement/:type',
    name: 'agreement',
    component: () => import(/* webpackChunkName: "about" */ '../views/AgreementDetail.vue'),
  },
  {
    path: '/cardDetail/:type',
    name: 'cardDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/CardDetail.vue'),
  }
]

/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch((error) => error);
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

/**
 * 路由拦截
 * 权限验证
 */

router.beforeEach(async (to, from, next) => {
  console.log(to,"to路由地址")
  if(to.path == '/') {
    // console.log(store.getters.getToken,'token')
    if(store.getters.getToken) {
      next()
    }else{
      router.push('/login')
    }
  } else {
    next()
  }
})

export default router
