import request from '@/utils/request/index';

/**
 * 绑卡
 * @param data object 卡号&手机号
 */
export function binding(data) {
	return request.post("jhapp/cbncard/binding", data);
}

/**
 * 转换积分
 * @param data object 积分&手机号
 */
export function conversion(data) {
	return request.post("jhapp/cbncard/transferIntegral", data);
}

/**
 * 获取我的卡/设备列表
*/ 
export function getIotList(params) {
	return request({
		url:"cbn/lists/data",
		method: 'get',
		params
	});
}