import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'normalize.css'
import './styles/base.css'
import './styles/iconfont.css'
import dayjs from 'dayjs'
// import './utils/rem.js';

Vue.use(ElementUI);

// 时间戳过滤
Vue.filter('activeTime',(time, pattern = 'YYYY-MM-DD') => {
  return dayjs(parseInt(time)).format(pattern);
})

Vue.config.productionTip = false
// console.log(process.meta.env,"111111")
const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default app
