import Vue from 'vue'
import Vuex from 'vuex'
import { Store } from "storage-timing";

Vue.use(Vuex)
const keys = {
  token: "token",
  userInfo: "userInfo",
}; 
const st = new Store();
const tokenStorage = st.atom(keys.token);
const userInfoStorage = st.atom(keys.userInfo);

export default new Vuex.Store({
  state: {
    token: tokenStorage.get() || null, // 用户 Token
    isLoggedIn: tokenStorage.get() ? true : false, // 用户登录状态
    userInfo: userInfoStorage.get() || {} // 用户信息
  },
  getters: {
    getToken: state => state.token,
    isLoggedIn: state => state.isLoggedIn,
    getUserInfo: state => state.userInfo
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      state.isLoggedIn = true;
      tokenStorage.set(token, Date.now() + 7 * 24 * 3600 * 1000);
    },
    logout(state) {
      state.token = null;
      state.isLoggedIn = false;
    },
    setUserInfo(state,userInfo) {
      state.userInfo = JSON.stringify(userInfo)
      userInfoStorage.set(JSON.stringify(userInfo), Date.now() + 7 * 24 * 3600 * 1000);
    }
  },
  actions: {
    login({ commit }, token) {
      commit('setToken', token);
    },
    logout({ commit }) {
      commit('logout');
    },
    toSetUserInfo({ commit },userInfo) {
      commit('setUserInfo',userInfo)
    }
  },
  modules: {
  }
})
